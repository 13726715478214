import React from 'react';
import './post.css';

export function moreOnTheLoops() {
  return (
    <div>
      <div className='para'>
        I read Hofstadter’s GEB around this time last year, I still don’t get what half the book is about. But it inevitably leads one to pick up his second work. It might well be illegal(read:
        unscientific) to attempt to explain an idea with arguments structured using the idea itself. However, the concept of strange loops has so completely altered my perception of consciousness,
        that this approach became inevitable.
      </div>
      <div className='para'>
        Hofstadter argues that our minds are fundamentally made up of loops, this should be easy to imagine, for the concept of self cannot arise otherwise. Consider this the simplest level of
        thought, you cannot possibly reference an object(any object really), without referencing to yourself first. In this way, it should be possible for all the objects inside one’s imagined
        perception, with all their individual attributes and distinguishing features to be broken down into fundamental bits of information(imagine, like binary). But this is where my imagination
        fails me, what goes on inside the mind of a baby that’s just come into the world, processing all that information and keeping only what’s necessary eludes me.
      </div>
      <div className='para'>
        It is very convenient to imagine a homunculus driving children to pick up the ‘necessary’ information and dump the rest. But in reality no information is really lost on us. All the data one
        processes is really metadata, one only feels the presence of that information strongly, when it has been sufficiently reinforced on the mind, again and again in a strange loop.
      </div>
      <div className='para'>
        I am quite taken by this theory, not only for the extraordinary yet elegant way in which it attempts to explain the functioning of the human mind, but also how snugly it fits in with the
        evolutionary understanding of consciousness. I wanted to say all this because I kind of felt like I left the hello_world post incomplete when I said this blog was an extension of my personal
        journal. I should probably write more frequently, and more about my regular days, let’s see how that works out.
      </div>
    </div>
  );
}
