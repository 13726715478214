import React from 'react';

export function helloWorld() {
  return (
    <div>
      <div className='para'>I have no idea how to begin writing this post, I've been writing and deleting whole lines for atleast half an hour.</div>
      <div className='para'>
        The problem with human memory in my opinion, is that while it is amazing in it's ability to record and store attributes separately to the tokens of information associated, the fidelity of
        reproduction unlike from mechanical memory is terribly poor. Whenever I've tried reminiscing good memories, I've always felt limited by the capacity of my own mind. Surely, the photographs and
        video recordings help, but a memory is what you feed inside your loop box and accept as the passed state. In this regard, I've found that the entries in my personal diary produce quite a
        unique effect that I can't quite explain yet.
      </div>
      <div className='para'>I intend for this blog to serve as an extension of that general experiment.</div>
    </div>
  );
}
