import React from 'react';

export function futility() {
  return (
    <div>
      <div className='para'>
        A moron is someone who believes in the superiority of their own mind, relative as the case may be. But do you ever come across people that are so unreasonably attached to their POV, that you'd
        rather just let the conversation pass than try reason? Logic at a certain level of abstraction flows not from the first principles, or the roots of reason. Let me point out, that the level of
        abstraction is in itself not definable, for it builds on top of itself, not in discrete layers, but as a smooth walled tower. But there should be a distinction made, because no matter the
        level of indoctrination, the roots of reason remain accessible to the human mind.
      </div>
      <div className='para'>
        I say 'the first principles' because for me, the scientific first principles help reasoning and inference where the time costs of confirming something with absolute reasoning are high. So, the
        question remains, if the roots of reason are more or less the same for everyone, how then in a disciplined argument, do people end up with different answers? The answer - *They don't!* I will
        not ponder over if scientific thought is inherently superior to other methods of reasoning, because to call the other methods 'reasoning' would in itself be against the process.
      </div>
      <div className='para'>So, what do we say to the strangely unreasonable people? Probably nothing at all.</div>
    </div>
  );
}
